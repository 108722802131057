<template>
  <div class="user-info">
    <div class="u-title">账户管理</div>
    <div class="u-container clearfix">
      <!-- 头像 -->
      <div class="u-img">
        <div>
          <span>我的头像:</span>
          <img :src="userInfo.user_avatar" class="avatar" />
          <!-- <img src="../../../assets/images/logo.png" class="avatar" /> -->
        </div>
        <el-upload class="avatar-uploader" action="/user/upload/portrait" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :headers="headers" name="image">
          <span>修改</span>
        </el-upload>
      </div>
      <!-- ID-->
      <div class="u-item">
        <div class="u-content">
          我的ID:&nbsp;
          <span>{{ userInfo.user_id }}</span>
        </div>
      </div>
      <!-- 我的昵称 -->
      <div class="u-item">
        <div class="u-content">
          我的昵称:
          <span>{{ userInfo.user_nickname }}</span>
        </div>
        <div class="u-txt"><span @click="dialogVisible = true">修改</span></div>
      </div>
      <!-- 退出登录 -->
      <div class="u-btn" @click="loginOut()">退出登录</div>
    </div>
    <el-dialog title="修改昵称" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <!-- 添加管理员表单 -->
      <el-form ref="nicknameRef" label-width="90px" :rules="nicknameRules" @submit.native.prevent :model="userInfo">
        <el-form-item label="昵称:" prop="user_nickname">
          <el-input type="text" v-model="userInfo.user_nickname"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editNickname">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getUserInfoApi, editUserInfoApi } from '@/api/user'
  export default {
    name: "userInfo",
    data() {
      return {
        nicknameRules: {
          user_nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }]
        },
        dialogVisible: false,
        headers: {
          authorization: `Bearer ${localStorage.sefpc_token}`
        },
        userInfo: {
          user_avatar: '',
          user_nickname: ''
        }
      }
    },
    created() {
      this.getUserInfo()
    },
    methods: {
      // 修改昵称
      editNickname() {
        this.$refs.nicknameRef.validate(valid => {
          if (!valid) return;
          this.editUserInfo()
          this.handleClose()
        })
      },
      // 关闭对话框
      handleClose() {
        this.dialogVisible = false
        this.$refs.nicknameRef.resetFields()
      },
      // 修改信息
      editUserInfo() {
        editUserInfoApi(this.userInfo).then(res => {
          console.log(res)
          this.$message.success(res.data.message)
          this.getUserInfo()
        })
      },
      //   上传头像成功回调
      handleAvatarSuccess(res) {
        this.userInfo.user_avatar = res.result.fileName[0]
        this.editUserInfo()
      },
      //   上传验证
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 1;

        if (!isJPG && !isPNG) {
          this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
          return
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
      },
      // 获取用户基本信息
      getUserInfo() {
        getUserInfoApi().then(res => {
          console.log(res)
          this.userInfo = res.data.result
          this.$emit('userAvatar', this.userInfo.user_avatar)
          this.$emit('userNickname', this.userInfo.user_nickname)
        })
      },
      //退出登录
      loginOut() {
        this.$confirm('您确定退出登录吗？', '退出登录确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.commit('setToken', '');
          this.$store.commit('setUserId', '');
          this.$store.commit('setUserAccount', '');
          this.$store.commit('setUserName', '');
          this.$router.push('/')
          this.$message.success('已成功退出')
        }).catch(() => {
          this.$message.info('已取消');
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  .user-info {
    padding: 20px 40px;

    .u-title {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      color: #282828;
      border-bottom: 1px solid #ddd;
    }

    .u-container {
      padding: 20px 40px;
      font-size: 14px;
      color: #666;

      .u-img {
        height: 120px;
        border-bottom: 1px dashed #ddd;

        div {
          display: inline-block;

          img {
            margin-left: 30px;
          }
        }

        div:nth-child(2) {
          float: right;
          margin-right: 20px;
          margin-top: 90px;

          span {
            color: #e1251b;
          }
        }

        .avatar {
          max-height: 100px;
        }
      }

      .u-item {
        height: 70px;
        border-bottom: 1px dashed #ddd;
        line-height: 70px;
        // display: flex;
        // justify-content: space-between;

        .u-content {
          float: left;

          span {
            margin-left: 40px;
            color: #333;
          }
        }

        .u-txt {
          margin-top: 15px;
          margin-right: 20px;
          color: #e1251b;
          float: right;

          span {
            cursor: pointer;
          }
        }
      }

      .u-btn {
        float: right;
        width: 130px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #e1251b;
        color: #fff;
        border-radius: 5px;
        margin: 40px 0px;
      }
    }
  }
</style>