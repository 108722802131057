import request from '@/libs/request'

/**
 * 获取用户地址列表
 */

export function getAddressListApi() {
  return request({
    url: '/index/address',
    method: 'get',
  })
}

/**
 * 用户收货地址修改
 **/
export function getAddressedit(id, data) {
  return request({
    url: `/index/address/${id}?${data}`,
    method: 'put',
  })
}

/**
 * 用户收货地址添加
 **/
export function postAddress(data) {
  return request({
    url: '/index/address',
    method: 'post',
    data
  })
}

/**
 * 获取地区列表
 */
export function getAreaListApi() {
  return request({
    url: '/index/address/create',
    method: 'get',
  })
}

/**
 * 获取地区列表
 */

export function getCollectListApi() {
  return request({
    url: '/index/address/create',
    method: 'get',
  })
}

/**
 * 添加收货地址表单提交
 */
export function addAddressSubmitApi(data) {
  return request({
    url: '/index/address',
    method: 'post',
    data
  })
}

/**
 * 修改收货地址表单提交
 */
export function editAddressSubmitApi(id, params) {
  return request({
    url: `/index/address/${id}`,
    method: 'put',
    params
  })
}

/**
 * 获取实名认证信息
 */
export function getUserExaminApi() {
  return request({
    url: `/user/auth_info`,
    method: 'get'
  })
}

/**
 * 提交实名认证信息
 */
export function userSafeSubmitApi(data) {
  return request({
    url: '/user/identity_auth',
    method: 'post',
    data
  })
}

/**
 * 用户收藏列表
 */
export function getUserCollectListApi(fav_type) {
  return request({
    url: `/index/user/collection?fav_type=${fav_type}`,
    method: 'get'
  })
}

/**
 * 删除收藏商品、店铺
 */
export function deleteUserCollectApi(favlog_id) {
  return request({
    url: `/index/user/collection/${favlog_id}`,
    method: 'delete'
  })
}

/**
 * 用户个人中心基本信息
 */
export function getUserInfoApi() {
  return request({
    url: '/user/info',
    method: 'get'
  })
}

/**
 * 修改用户头像昵称
 */
export function editUserInfoApi(data) {
  return request({
    url: '/index/user/fieldInfo',
    method: 'post',
    data
  })
}

/**
 * 修改密码
 */
export function editPwdApi(data) {
  return request({
    url: '/index/user/password',
    method: 'post',
    data
  })
}